/*======================================
 8. Home-style-5 
 =======================================*/

/* 8.1 wpo-features-area-s3 */

.wpo-features-area-s3 {
	padding: 80px 0 0;
}


/* 8.2 wpo-about-area-s5 */

.wpo-about-area-s5 {
	.wpo-about-img .wpo-about-item {
		background-color: $black-bg-color-s3;

		.wpo-about-top .wpo-about-info {

			h2 {
				color: $white;
			}

			span {
				color: $black-section-text-color;
			}
		}

		.wpo-about-content {
			p {
				color: $black-section-text-color;
			}
		}
	}

}

/* 8.3 wpo-team-section-s2 */

.wpo-team-section-s2 {
	background-color: $black-bg-color-s2;

	.wpo-section-title {
		h2 {
			color: $white;
		}
	}

	.wpo-team-wrap .wpo-team-item {
		.wpo-team-text {
			background: $black-bg-color-s2;

			h3 {
				a {
					color: $white;
				}
			}

			ul li a {
				border: 1px solid $border-color-dark;
				color: $black-section-text-color;
			}
		}
	}

}