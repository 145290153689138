// page-title
.wpo-page-title{
    background: url(../../images/page-title.jpg) no-repeat center top / cover;
    min-height: 450px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;

	@media(max-width: 767px) {
		min-height: 250px;
	}

	&:before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $black-bg-color;
		content: "";
		z-index: -1;
		opacity: .50;
	}
	.wpo-breadcumb-wrap {
		text-align: center;

		h2{
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-bottom: 10px;
			font-weight: 700;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ol{
			padding-left: 0;
			margin-bottom: 0;
			li{
				display: inline-block;
				padding: 0px 5px;
				padding-right: 20px;
				color: #fff;
				position: relative;
				font-size: 22px;
				font-family: $heading-font;

				@media(max-width:767px){
					font-size: 18px;
				}

				&:last-child{
					padding-right: 0;
				}

				&:after{
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 8px;
					height: 8px;
					background: $white;
					border-radius: 50%;
				}

				&:last-child{
					span{
						color: #cbd4fd;
					}
					&:after{
						display: none;
					}
				}

				a{
					color: #fff;
					font-size: 22px;
					transition: all .3s;

					&:hover{
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}

