.wpo-section-title,
.wpo-section-title-s2,
.wpo-section-title-s3 {
	margin-bottom: 60px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	p {
		font-weight: 400;
		font-size: 20px;
		line-height: 115.3%;
		color: $theme-primary-color;
		font-family: $base-font;
		display: block;
		display: inline-block;
		position: relative;
		margin-bottom: 20px;

		&:after{
			position: absolute;
			left: -60px;
			top: 50%;
			width: 50px;
			height: 1px;
			content: "";
			background: $theme-primary-color;
		}
		&:before{
			position: absolute;
			right: -60px;
			top: 50%;
			width: 50px;
			height: 1px;
			content: "";
			background: $theme-primary-color;
		}

		@include media-query(575px) {
			font-size: 15px;
		}
	}

	h2 {
		font-weight: 600;
		font-weight: 400;
		font-size: 100px;
		line-height: 115.3%;
		margin: 0;
		position: relative;
		font-family: $heading-font;
		color: $dark-gray;
		display: block;
		position: relative;

		span{
			color: $theme-primary-color;
		}

		@include media-query(991px) {
			font-size: 55px;
			line-height: 65px;
		}
		@include media-query(767px) {
			font-size: 45px;
			line-height: 55px;
		}
		@include media-query(575px) {
			font-size: 35px;
			line-height: 45px;
		}


	}

	p {
		font-size: 18px;

		@include media-query(991px) {
			margin-bottom: 10px;
		}
	}

}

.wpo-section-title-s2 {
	position: relative;
	z-index: 1;
	text-align: left;
	margin-bottom: 15px;

	p{
		padding-left: 70px;

		&:after{
			position: absolute;
			left: -60px;
			top: 50%;
			width: 50px;
			height: 1px;
			content: "";
			background: $theme-primary-color;
		}
		&:before{
			display: none;
		}
		&:after{
			left: 0;
		}
	}
}

// wpo-section-title-s3

.wpo-section-title-s3 {
	h2 {
		color: $white;
	}
}
