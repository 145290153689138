/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&family=Teko:wght@300;400;500;600;700&display=swap');


// fonts
$base-font-size: 18;
$base-font: 'Mulish', sans-serif;
$heading-font: 'Teko', sans-serif;


// color
$dark-gray: #222222;
$body-color: #595959;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #C3C2CA;
$cyan2: #ddd;
$black-section-text-color:#CBCBCB;

$theme-primary-color: #ddc071;
$theme-primary-color-s2: #F45325;
$body-bg-color: #fff;
$section-bg-color: #F9F9F9;
$black-bg-color: #212428;
$black-bg-color-s2: #1f2226;
$black-bg-color-s3: #22262a;
$text-color: #585858;
$text-light-color: #525252;
$heading-color: $dark-gray;
$border-color: #D9D9D9;
$border-color-s2: #e4ebf2;
$border-color-dark: #31363c;
