.sticky-header {
	width: 100%;
	position: sticky;
	left: 0;
	top: -200px;
	z-index: 9999;
	opacity: 0;
	@include transition-time(0.7s);
}

.sticky-on {
	opacity: 1;
	top: 0;
}
